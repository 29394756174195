import hussarsimg from './images/hussars artwork.png';

/*import instalogo from './images/original instagram logo.png';
import soundcloudlogo from './images/original soundcloud logo.png';
import spotifylogo from './images/original spotify logo.png';
import youtubelogo from './images/original youtube logo.png';
*/

import instalogo from './images/Instagram-Logo-Coloured.png';
import youtubelogo from './images/Youtube-Logo-Coloured.png';
import spotylogo from './images/Spotify-Logo-Coloured.png';
import soundcloudlogo from './images/Soundcloud-Logo-Coloured.png';

import instalogogray from './images/Instagram-Logo-Gray.png';
import youtubeloggray from './images/Youtube-Logo-Gray.png';
import spotylogogray from './images/Spotify-Logo-Gray.png';
import soundcloudlogogray from './images/Soundcloud-Logo-Gray.png';

function Home() {
    return(
        <div className='background'>
            
            <div className='pageContent'>
                
                <h1>LATEST PROJECT</h1>   
                <div id='latestrelease' className='release'>
                    <div className='artwork'>
                        <a href='https://soundcloud.com/percules_prod/hussars-1'>
                                <img src={hussarsimg} alt="."/>
                        </a>
                    </div>
                    <div className='songName'><span>Hussars</span></div>
                </div>
                
                <div id='music'>
                    <iframe width="100%" height="100%" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1536047323&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                </div>
                
                <div id='border'></div>

                <div id='socials' /*className={this.state.clicked?'#socials active':'#socials'}*/>

                    <h1>SOCIALS</h1>

                    <ul>
                        <li>
                            <a href='https://soundcloud.com/percules_prod'>
                                <div className='imgArea'>
                                     <img src={soundcloudlogogray} className='imgback'alt="."/>
                                    <img src={soundcloudlogo} className='imgfront'alt="."/>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href='https://open.spotify.com/artist/4CiaC3NSCRnNk010PeOqNo?si=9SsMQVTjTNCb9IQ3qQYQbg'>
                                <div className='imgArea'>
                                     <img src={spotylogogray} className='imgback'alt="."/>
                                    <img src={spotylogo} className='imgfront'alt="."/>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href='https://www.instagram.com/percules_prod/'>
                                <div className='imgArea'>
                                     <img src={instalogogray} className='imgback'alt="."/>
                                    <img src={instalogo} className='imgfront'alt="."/>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href='https://www.youtube.com/channel/UCnGZSSfYA3D-oLAvTHszu-Q'>
                                <div className='imgArea'>
                                     <img src={youtubeloggray} className='imgback'alt="."/>
                                    <img src={youtubelogo} className='imgfront'alt="."/>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                <div id='border'></div>

                <div id='contact'>
                    <h1>CONTACT</h1>
                    <p>Email: perculesprod@proton.me</p>
                    <p>(Newsletter soon)</p>
                    <p>_____________________________</p>
                    <p>Discord: Martin H#4346</p>
                    <p>(Currently open to collab)</p>
                </div>
            </div>

            {/*
                
                   <div id='socialshome'>
                    <img src={soundcloudlogo}></img>
                    <img src={spotifylogo}></img>
                    <img src={instalogo}></img>
                    <img src={youtubelogo}></img>
                   </div>
            */}
            
                
            
           
            <div id='empty'></div>
            <h2>Don't worry, there is more to come here yet ...</h2>
        
        </div>   
    );
}
export default Home;