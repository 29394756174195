import {Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar.js'
import Home from './components/Home.js'
import About from './components/About.js'
import Songs from './components/Songs.js'
import Videos from './components/Videos.js'
import Footer from './components/Footer.js'
import Releases from './components/Releases.js'

import './App.css';

function App() {
  return (
    <>
    <Navbar></Navbar>
      <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/about' element={<About />}/>            
            <Route path='/songs' element={<Songs />}/>
            <Route path='/videos' element={<Videos />}/>
            <Route path='/releases' element={<Releases/>}/>

        </Routes>  
      <Footer></Footer>
    </>  
  );
}

export default App;
