function Videos() {
  return (
    <>
      <div className="background">
        <div className='pageContent'>
          <h1>VIDEOS</h1>
          <div id='videolist'>
            <div className='video'>
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/4VmIqy1MHuI?si=DwXh70JxOdJBmN2U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

            <div className='video'>
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/DMHo5oL7mfk?si=MaQW5uqYE9X79p_j" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

            <div className='video'>
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/i4AU4QQwcvE?si=mrdynWOswH6FH6lh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

            <div className='video'>
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/HhuwMQtRHuk?si=37XLq00tiRAPAix_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

            <div className='video'>
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/i4AU4QQwcvE?si=83KAGoQi7FIWBJEe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

            <div className='video'>
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/_YFSsY0a_Lk?si=d474bvJ_7U33a_x6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

            <div className='video'>
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/QVdNOnO8QPw?si=rmsIiSWmlmVp0W71" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

            <div className='video'>
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/UTFkKjqT7a4?si=QmRPuiTWzhE9I3UJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div id='empty'></div>
      </div>
    </>
  )
}

export default Videos;