import hussarsimg from './images/hussars artwork.png';
import escapefromgulagimg from './images/escape from gulag artwork.png';
import evolveimg from './images/evolve artwork.png';
import manowarimg from './images/man o war artwork.png';
import overcomeimg from './images/overcome artwork.png';
import brokenrulesimg from './images/broken rules artwork.png';
import labyrinthimg from './images/labyrinth artwork.png';

function Releases() {
    return(
        <div className='background'>
            <div className='pageContent'>
                <h1>RELEASES</h1>   
                <div id='releaseList'>
                    <div className='release'>
                        <div className='artwork'>
                            <a href='https://soundcloud.com/percules_prod/hussars-1'>
                                    <img src={hussarsimg} alt="."/>
                            </a>
                        </div>
                        <div className='songName'><span>Hussars</span></div>
                    </div>
                    
                    <div className='release'>
                        <div className='artwork'>
                            <a href='https://soundcloud.com/percules_prod/escape-from-gulag'>
                                    <img src={escapefromgulagimg} alt="."/>
                            </a>
                        </div>
                        <div className='songName'><span>Escape from Gulag</span></div>
                    </div>

                    <div className='release'>
                        <div className='artwork'>
                            <a href='https://soundcloud.com/percules_prod/evolve'>
                                    <img src={evolveimg} alt="."/>
                            </a>
                        </div>
                        <div className='songName'><span>Evolve</span></div>
                    </div>

                    <div className='release'>
                        <div className='artwork'>
                            <a href='https://soundcloud.com/percules_prod/man-o-war'>
                                    <img src={manowarimg} alt="."/>
                            </a>
                        </div>
                        <div className='songName'><span>Man o' War</span></div>
                    </div>

                    <div className='release'>
                        <div className='artwork'>
                            <a href='https://soundcloud.com/percules_prod/overcome'>
                                    <img src={overcomeimg} alt="."/>
                            </a>
                        </div>
                        <div className='songName'><span>Overcome</span></div>
                    </div>

                    <div className='release'>
                        <div className='artwork'>
                            <a href='https://soundcloud.com/percules_prod/broken-rules'>
                                    <img src={brokenrulesimg} alt="."/>
                            </a>
                        </div>
                        <div className='songName'><span>Broken Rules</span></div>
                    </div>

                    <div className='release'>
                        <div className='artwork'>
                            <a href='https://soundcloud.com/percules_prod/labyrinth'>
                                    <img src={labyrinthimg} alt="."/>
                            </a>
                        </div>
                        <div className='songName'><span>Labyrinth</span></div>
                    </div>        
                </div> 
            </div>
            <div id='empty'></div>
        </div>
    );
}
export default Releases;