function Songs() {
    return(
        <div id='contact' className="background">
            <div className='pageContent'>
                <h1>SONGS</h1>

                <div id='music'>
                    <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1536047323&color=%232d1d17&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                    <div>
                        <a href="https://soundcloud.com/percules_prod" title="Percules" target="_blank">Percules</a>
                        <a href="https://soundcloud.com/percules_prod/hussars-1" title="Hussars" target="_blank">Hussars</a>
                    </div>

                    <iframe width="100%" height="100%" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1536047323&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
                    <div>
                        <a href="https://soundcloud.com/percules_prod" title="Percules" target="_blank">Percules</a>
                        <a href="https://soundcloud.com/percules_prod/hussars-1" title="Hussars" target="_blank" >Hussars</a>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Songs;