import { Component } from 'react';
import {NavLink} from 'react-router-dom';

import logo from './images/Percules-Logo.png';
import instalogo from './images/Instagram-Logo-Coloured.png';
import youtubelogo from './images/Youtube-Logo-Coloured.png';
import spotylogo from './images/Spotify-Logo-Coloured.png';
import soundcloudlogo from './images/Soundcloud-Logo-Coloured.png';

import instalogogray from './images/Instagram-Logo-Gray.png';
import youtubeloggray from './images/Youtube-Logo-Gray.png';
import spotylogogray from './images/Spotify-Logo-Gray.png';
import soundcloudlogogray from './images/Soundcloud-Logo-Gray.png';

class Navbar extends Component {
    state={ clicked: false};
    handleClick=()=>{
        this.setState({clicked:!this.state.clicked})
    }
    render(){
        return(
            <>
            <nav>
                <div id='perculeslogo'><img src={logo}></img></div>
                <div></div>

                <div id='navPages'>
                    <ul id='navlinks' className={this.state.clicked?'#navlinks active':'#navlinks'}>
                        <li>
                            <NavLink to='/' id='homebutton' className='navLink'>HOME</NavLink>
                        </li>
                        <li>
                            <NavLink to='/releases' className='navLink'>RELEASES</NavLink>
                        </li>
                        <li>
                            <NavLink to='/videos' className='navLink'>VIDEOS</NavLink>
                        </li>
                        <li>
                            <NavLink to='/about' className='navLink'>ABOUT</NavLink>
                        </li>
                    </ul>
                </div>
                
                <div id='mobile' onClick={this.handleClick}>
                    <i id='bar' className={this.state.clicked ? 'fas fa-times':'fas fa-bars'}></i>
                </div>
        </nav> 
        <div id='border'></div>
        <div id='invisible'></div>
        </>
        );
    }
}

export default Navbar;