import perculesinthestudio from './images/percules in the studio.jpg';

function About() {
    return(
        <div className="background">
            <div id='about' className='pageContent'>
                <h1>ABOUT</h1>
                <div id='aboutcontainer'>
                    <img src={perculesinthestudio}></img>
                    <span>
                    Hailing from eastern europe, Percules is a 22 Year old music producer who makes trap music…with more of a cinematic touch. 
                    Why the name? "Perc" for percussion and (her)-cules for representing the epicness of his symphonies. 
                    Music was the source of his strength in challenging times, now he wants to encourage others with his own compositions. 
                    Under another alias “Havazy”, he Started out making beats at 17 years and soon collaborated with fellow semi professional rappers from the underground of munich. 
                    In 2020 he achieved over 200k streams for a remix on Soundcloud. However, selling beats within the mainstream hip-hop genre was a chain for his creativity and for his musical independence. 
                    Knowing this, he ventured into the world of the bass trap genre, where he still remains today. 
                    </span>
                </div>
            </div>
            <div id='empty'></div>

        </div>
        
    );
}
export default About;