import logonewraw from './images/logo new raw.png';
function Footer() {
    return(
        <div id='footer'>
            <img src={logonewraw}></img>
            <div>© Percules</div>
            <div>Site Designed and Programmed by Percules</div>

        </div>
    );
}
export default Footer;